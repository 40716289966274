<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Domains</span>
      </v-card-title>
      <v-card-text>
        <v-container :key="domains.length">
          <v-row v-for="(domain, index) in domains" :key="`domain${index}`">
            <v-col class="p-0">
              <v-text-field
                label="Domain *"
                required
                v-model="domains[index].domain"
              ></v-text-field>
            </v-col>
            <v-col class="p-0 pl-2" cols="3">
              <v-text-field
                label="Max links *"
                required
                type="number"
                v-model="domains[index].usage_limit"
              ></v-text-field>
            </v-col>
            <v-col class="p-0 pl-2" cols="3">
              <v-select
                :items="BROADCAST_TARGETS"
                label="Target*"
                item-text="name"
                item-value="value"
                v-model="domains[index].target"
              ></v-select>
            </v-col>
            <v-col cols="auto" v-if="domains.length > 1" class="d-flex">
              <v-btn
                icon
                color="red"
                @click="onDeleteDomain(index)"
                class="m-auto"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="p-0">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="primary"
                title="Add Domain"
                @click="addDomain"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="toggleOpenModal">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text type="button" @click="onCreateClick">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { CREATE_DOMAINS } from "../../../store/modules/domains/types";
import { BROADCAST_TARGETS } from "@/services/services";

export default {
  name: "Create",
  props: {
    toggleOpenModal: Function,
    isOpen: Boolean,
  },
  data: () => ({
    domains: [{ domain: "", usage_limit: "", target: "" }],
  }),
  computed: {
    ...mapState({
      loading: (state) => state.domains.loading,
    }),
    BROADCAST_TARGETS: () => BROADCAST_TARGETS,
  },
  methods: {
    ...mapActions({
      onCreate: `domains/${CREATE_DOMAINS}`,
    }),
    addDomain() {
      this.domains.push({ domain: "", usage_limit: "", target: "" });
    },
    onCreateClick() {
      const allHaveBroadcastTarget = this.domains.every(
        (item) => item.target !== ""
      );

      if (allHaveBroadcastTarget) {
        this.onCreate(this.domains);
        this.toggleOpenModal();
      } else {
        this.$toaster.error("Set target");
      }
    },
    onDeleteDomain(index) {
      this.domains.splice(index, 1);
      // this.domains = [...this.domains];
    },
  },
};
</script>

<style scoped></style>
