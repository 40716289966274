<template>
  <v-container :fluid="true">
    <domains-table :toggleOpenCreateDomain="toggleOpenCreateDomain" />
    <create :is-open="isShowCreateDomain" :toggle-open-modal="toggleOpenCreateDomain" />
  </v-container>
</template>

<script>
import DomainsTable from "./components/Table";
import Create from "./components/Create";
import {mapActions, mapMutations} from "vuex";
import {SET_DEFAULT_STATE} from "../../store/modules/domains/types";
import {ADD_HEADER_SETTINGS} from "../../store/modules/layout/types";

export default {
  name: "Contacts",
  components: {DomainsTable, Create},
  data: () => ({
    isShowCreateDomain: false
  }),
  methods: {
    ...mapActions({
      addSettings: `layout/${ADD_HEADER_SETTINGS}`,
    }),
    ...mapMutations({
      onClearData: `domains/${SET_DEFAULT_STATE}`,
    }),
    toggleOpenCreateDomain() {
      this.isShowCreateDomain = !this.isShowCreateDomain;
    },
  },
  beforeMount() {
    this.addSettings({
      pageName: 'Domains',
      actions: []
    })
  },
  beforeRouteLeave(to, from, next) {
    this.onClearData();
    next();
  }
}
</script>

<style scoped>

</style>