<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary"
             icon small
             title="Edit max links"
             v-bind="attrs"
             v-on="on">
        <v-icon small
        >mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Edit {{ domain.domain }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
                label="Max links *"
                required
                type="number"
                v-model="usage_limit"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="edit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

export default {
  props: ['domain'],
  data: () => ({
    dialog: false,
    usage_limit: ''
  }),
  methods: {
    edit(){
      this.dialog = false;
      this.$emit('onEdit', {usage_limit: this.usage_limit});
    }
  },
  watch: {
    domain(newValue) {
      this.usage_limit = newValue.usage_limit;
    },
    dialog() {
      this.usage_limit = this.domain.usage_limit;
    }
  }
}
</script>

<style scoped>

</style>